import './App.css';
import {Model} from './Compilation';
import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { useState } from 'react';


function ModelScreen() {
  return (
    <Canvas
         camera={{ position: [206, 48, -167], fov: 15 }}
         style={{
            backgroundColor: '#111a21',
            width: '100vw',
            height: '100vh',
         }}
      >
         <ambientLight intensity={1.25} />
         <ambientLight intensity={0.1} />
         <directionalLight intensity={0.4} />
         <Suspense fallback={null}>
          <Model position={[0.025, -0.9, 0]} />
         </Suspense>
         <OrbitControls />
      </Canvas>
  )
}

function App() {
  const [isRender, setRender] = useState(false)

  const toggleIsRender = () => {
    setRender(isRender => !isRender);
  }

  if (isRender) {
    return (
      <>
        <header className='header'>
          <h1 className='title'>theoske.me</h1>
          <button className='info-button' onClick={toggleIsRender}>see information</button>
        </header>
        <ModelScreen />
      </>
     );
  } else {
   return (
    <>
      <header className='header'>
        <h1 className='title'>theoske.me</h1>
        <button className='info-button' onClick={toggleIsRender}>see render</button>
      </header>
      <div className='info'>
        I did this simple website to test threejs to render 3d models i made with blender. <br />Complex shapes and textures dont render properly or at all.
      </div>
    </>
   );
  }
}

export default App;
