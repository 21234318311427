/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 /Users/theoke/Dev/website/threejs-app/public/compilation.glb 
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/compilation.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={1.924}>
        <mesh geometry={nodes.Icosphere.geometry} material={materials.feuille} position={[3.107, 8.57, -0.382]} scale={0.732}>
          <mesh geometry={nodes.Icosphere001.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere002.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere003.geometry} material={materials.feuille} position={[1.524, 8.346, 2.338]} rotation={[-2.424, 0, 0]} scale={0.732}>
          <mesh geometry={nodes.Icosphere004.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere005.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere006.geometry} material={materials.feuille} position={[-2.485, 6.971, -1.163]} rotation={[-2.424, 0, 0]} scale={1.075}>
          <mesh geometry={nodes.Icosphere007.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere008.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere009.geometry} material={materials.feuille} position={[0.684, 7.083, -3.98]} rotation={[1.391, 0.01, -0.017]} scale={1.075}>
          <mesh geometry={nodes.Icosphere010.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere011.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
      </group>
      <group position={[-6.425, -0.464, -8.174]} rotation={[-3.059, 1.287, 3.09]}>
        <mesh geometry={nodes.Icosphere012.geometry} material={materials.feuille} position={[3.107, 8.57, -0.382]} scale={0.732}>
          <mesh geometry={nodes.Icosphere013.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere014.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere015.geometry} material={materials.feuille} position={[1.524, 8.346, 2.338]} rotation={[-2.424, 0, 0]} scale={0.732}>
          <mesh geometry={nodes.Icosphere016.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere017.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere018.geometry} material={materials.feuille} position={[-2.485, 6.971, -1.163]} rotation={[-2.424, 0, 0]} scale={1.075}>
          <mesh geometry={nodes.Icosphere019.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere020.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere021.geometry} material={materials.feuille} position={[0.684, 7.083, -3.98]} rotation={[1.391, 0.01, -0.017]} scale={1.075}>
          <mesh geometry={nodes.Icosphere022.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} rotation={[0.031, 0.094, -0.551]} scale={1.41} />
          <mesh geometry={nodes.Icosphere023.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
      </group>
      <group position={[3.368, -0.464, 7.389]} scale={0.686}>
        <mesh geometry={nodes.Icosphere024.geometry} material={materials.feuille} position={[3.107, 8.57, -0.382]} scale={0.732}>
          <mesh geometry={nodes.Icosphere025.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere026.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere027.geometry} material={materials.feuille} position={[1.524, 8.346, 2.338]} rotation={[-2.424, 0, 0]} scale={0.732}>
          <mesh geometry={nodes.Icosphere028.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere029.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere030.geometry} material={materials.feuille} position={[-2.485, 6.971, -1.163]} rotation={[-2.424, 0, 0]} scale={1.075}>
          <mesh geometry={nodes.Icosphere031.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere032.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere033.geometry} material={materials.feuille} position={[0.684, 7.083, -3.98]} rotation={[1.391, 0.01, -0.017]} scale={1.075}>
          <mesh geometry={nodes.Icosphere034.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere035.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
      </group>
      <group position={[9.217, -0.573, 0.986]} rotation={[Math.PI, -0.674, Math.PI]} scale={1.267}>
        <mesh geometry={nodes.Icosphere036.geometry} material={materials.feuille} position={[3.107, 8.57, -0.382]} scale={0.732}>
          <mesh geometry={nodes.Icosphere037.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere038.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere039.geometry} material={materials.feuille} position={[1.524, 8.346, 2.338]} rotation={[-2.424, 0, 0]} scale={0.732}>
          <mesh geometry={nodes.Icosphere040.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere041.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere042.geometry} material={materials.feuille} position={[-2.485, 6.971, -1.163]} rotation={[-2.424, 0, 0]} scale={1.075}>
          <mesh geometry={nodes.Icosphere043.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere044.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
        <mesh geometry={nodes.Icosphere045.geometry} material={materials.feuille} position={[0.684, 7.083, -3.98]} rotation={[1.391, 0.01, -0.017]} scale={1.075}>
          <mesh geometry={nodes.Icosphere046.geometry} material={materials.feuille} position={[-0.487, 0.131, 1.211]} scale={1.41} />
          <mesh geometry={nodes.Icosphere047.geometry} material={materials.feuille} position={[-1.436, -0.182, 0.089]} scale={1.213} />
        </mesh>
      </group>
      <mesh geometry={nodes.Plane.geometry} material={materials.feuille} scale={36.705} />
      <mesh geometry={nodes.Cylinder.geometry} material={materials.wood} position={[2.351, 1.751, -6.571]} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials.metal} position={[2.351, 1.608, -6.571]} scale={1.375} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials.metal} position={[2.351, -0.435, -6.571]} scale={1.354} />
      <mesh geometry={nodes.Cylinder003.geometry} material={materials.metal} position={[2.351, 0.37, -6.571]} scale={1.578} />
      <group position={[2.392, 3.691, -6.646]} rotation={[-2.402, 1.341, 2.432]}>
        <mesh geometry={nodes.Sphere_1.geometry} material={materials.citrouille} />
        <mesh geometry={nodes.Sphere_2.geometry} material={materials.tige} />
      </group>
      <group position={[-8.092, 25.248, 0.936]} rotation={[0, -0.488, 0]}>
        <mesh geometry={nodes.Cylinder004_1.geometry} material={materials.fusee} />
        <mesh geometry={nodes.Cylinder004_2.geometry} material={materials.rouge} />
      </group>
      <group position={[-10.663, 25.571, -0.539]} rotation={[1.974, -0.68, -2.519]}>
        <mesh geometry={nodes.Cylinder005_1.geometry} material={materials.rouge} />
        <mesh geometry={nodes.Cylinder005_2.geometry} material={materials['Material.003']} />
      </group>
      <mesh geometry={nodes.Smoke_Domain.geometry} material={materials['Smoke Domain Material']} position={[-8.109, 21.998, 0.927]} rotation={[0, -0.488, 3.103]} scale={[1.891, 2.891, 1.891]} />
      <mesh geometry={nodes.Sphere001.geometry} material={nodes.Sphere001.material} position={[-8.075, 24.318, 0.945]} rotation={[0, -0.488, 3.103]} scale={-0.438} />
      <mesh geometry={nodes.Sphere003.geometry} material={materials['glass.002']} position={[5.327, 0.827, 0.635]} rotation={[0, 0.874, 0]} scale={0.735}>
        <mesh geometry={nodes['Etoiles-DBZ'].geometry} material={materials['Etoiles-DBZ']} position={[1.009, 0, 0.007]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={2.094} />
        <mesh geometry={nodes.Sphere002.geometry} material={materials.ball} position={[-0.003, 0, 0.007]} scale={0.54} />
      </mesh>
      <mesh geometry={nodes.Sphere005.geometry} material={materials['glass.002']} position={[4.962, 0.828, -0.94]} rotation={[0, 0.693, 0]} scale={0.735}>
        <mesh geometry={nodes['Etoiles-DBZ001'].geometry} material={materials['Etoiles-DBZ.001']} position={[1.009, 0, 0.007]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={2.103} />
        <mesh geometry={nodes.Sphere004.geometry} material={materials.ball} position={[-0.003, 0, 0.007]} rotation={[0, 0.484, 0]} scale={0.54} />
      </mesh>
      <mesh geometry={nodes.Sphere007.geometry} material={materials['glass.003']} position={[4.804, 0.731, -2.161]} rotation={[0, 0.671, 0]} scale={0.735}>
        <mesh geometry={nodes['Etoiles-DBZ002'].geometry} material={materials['Etoiles-DBZ.002']} position={[1.009, 0.058, 0.007]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={1.959} />
        <mesh geometry={nodes.Sphere006.geometry} material={materials.ball} position={[-0.003, 0, 0.007]} scale={0.54} />
      </mesh>
      <mesh geometry={nodes.Sphere009.geometry} material={materials['glass.003']} position={[3.341, 0.731, -2.597]} rotation={[0, 0.688, 0]} scale={0.735}>
        <mesh geometry={nodes['Etoiles-DBZ003'].geometry} material={materials['Etoiles-DBZ.003']} position={[1.009, 0, 0.007]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={1.982} />
        <mesh geometry={nodes.Sphere008.geometry} material={materials.ball} position={[-0.003, 0, 0.007]} scale={0.54} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/compilation.glb')
